<script setup>
const navItem = [
    {
        name: 'Главная',
        link: '/',
        subLink: []
    },
    {
        name: 'Кондиционирование',
        link: '/conditioning',
        subLink: [
            {
                name: 'Установка кондиционеров',
                link: '/conditioning/installing-air-conditioner',
                subLink: []
            },
            {
                name: 'Ремонт кондиционеров',
                link: '/conditioning/repair-air-conditioner',
                subLink: []
            },
            {
                name: 'Обслуживание кондиционеров',
                link: '/conditioning/air-conditioning-maintenance',
                subLink: []
            },
        ]
    },
    {
        name: 'Вентиляция',
        link: '/ventilation',
        subLink: [
            {
                name: 'Установка вентиляции',
                link: '/ventilation/installation-ventilation',
                subLink: []
            },
            {
                name: 'Ремонт вентиляции',
                link: '/ventilation/repair-ventilation',
                subLink: []
            },
            {
                name: 'Обслуживание вентиляции',
                link: '/ventilation/ventilation-maintenance',
                subLink: []
            },
        ]
    },
    {
        name: 'Каталог кондиционеров',
        link: '/catalog',
        subLink: [
            {
                name: 'Кондиционеры настенные',
                link: '/catalog/split-system',
                subLink: []
            },
            {
                name: 'Кондиционеры мульти-сплит',
                link: '/catalog/multi-split-system',
                subLink: []
            },
            {
                name: 'Кондиционеры кассетные',
                link: '/catalog/kassetnie',
                subLink: []
            },
            {
                name: 'Кондиционеры напольно-потолочные',
                link: '/catalog/napolno-potolochnie',
                subLink: []
            },
            {
                name: 'Кондиционеры колонные',
                link: '/catalog/kolonnie',
                subLink: []
            },
            {
                name: 'Кондиционеры канальные',
                link: '/catalog/kanalnie',
                subLink: []
            }
        ]
    },
    {
        name: 'Статьи',
        link: '/articles',
        subLink: []
    },
    {
        name: 'О Нас',
        link: '/about',
        subLink: []
    },
    {
        name: 'Контакты',
        link: '/contacts',
        subLink: []
    }
];
</script>

<template>
    <footer class="footer-medium">
        <div class="footer-medium__content-wrapper">
            <div class="footer-medium__content">
                <FooterDescriptionMedium class="footer-medium__description" />
                <FooterContactsMedium class="footer-medium__contacts" />
                <FooterFormMedium class="footer-medium__form" />
                <nav class="footer-medium__link-wrapper">
                    <ul class="footer-medium__link-list" itemscope='itemscope'
                        itemtype='http://schema.org/SiteNavigationElement'>
                        <li v-for="item in navItem" class="footer-medium__link-list-item">
                            <NuxtLink :to="item.link" :key="item.link" itemprop='url' class="footer-medium__link">
                                <span itemprop="name" :content="item.name">{{ item.name }}</span>
                            </NuxtLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="footer-medium__copyright-wrapper">© 2024 Air Arts</div>
        </div>
    </footer>
</template>

<style lang="less">
.footer-medium {
    height: 520px;
    background: top left / 944.4px 520px no-repeat url('@/assets/img/footer-block-background4.svg'),
        top right -78px / 656px 520px no-repeat url('@/assets/img/footer-block-background1.svg'),
        top left / 480px 520px no-repeat url('@/assets/img/footer-block-background2.svg'),
        top center / 1440px 100% no-repeat url('@/assets/img/footer-block-background3.svg'),
        top center / 480px 520px no-repeat url('@/assets/img/footer-block-background2.svg'),
        top right / 480px 520px no-repeat url('@/assets/img/footer-block-background2.svg');

    &__content {
        width: 970px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;


        &-wrapper {
            width: 100%;
            height: 520px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__link {
        font-family: 'Proxima Nova Rg';
        font-size: 1.2rem;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: #7b897d;
        cursor: pointer;
        text-decoration: none;
        transition: 0.3s color;

        &-list {
            width: 200px;
            list-style: none;
            padding: unset;

            &-item {
                border-bottom: 1px solid #bee1c2;

                &:last-child {
                    border-bottom: none;
                }
            }

        }

        &:hover {
            color: var(--text2);
            transition: 0.3s color;
        }

        &-wrapper {
            width: 200px;
            margin-top: 82px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__copyright-wrapper {
        font-family: 'Proxima Nova Rg';
        font-size: 1.4rem;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: uppercase;
        margin-top: 5px;
        color: #7b897d;
    }
}
</style>
